import React from "react";
import { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
// import ButtonSecondary from "shared/Button/ButtonSecondary";

export interface CommonLayoutProps {
  index: string;
  nextHref?: string;
  backtHref?: string;
  nextBtnText?: string;
  children: React.ReactNode;
  nextFunction?: ((event: any) => void);
  disableConditionsInput?: boolean;
  disbleConditionSelect?: boolean;
  BtnLoading?: boolean; 
}

const CommonLayout: FC<CommonLayoutProps> = ({
  index = "01",
  children,
  nextHref,
  nextFunction,
  nextBtnText,
  backtHref,
  disableConditionsInput,
  disbleConditionSelect,
  BtnLoading
}) => {
  return (
    <div
      className={`nc-PageAddListing1 px-4 max-w-3xl mx-auto pb-24 pt-14 sm:py-24 lg:pb-32`}
      data-nc-id="PageAddListing1"
    >
      <div className="space-y-11">
        <div>
          <span className="text-4xl font-semibold">{index}</span>{" "}
          <span className="text-lg text-neutral-500 dark:text-neutral-400">
            / 01
          </span>
        </div>

        {/* --------------------- */}
        <div className="listingSection__wrap ">{children}</div>

        {/* --------------------- */}
        <div className="flex justify-end space-x-5">
          {/* <ButtonSecondary href={backtHref}>Volver</ButtonSecondary> */}
          <ButtonPrimary disabled={disableConditionsInput || disbleConditionSelect} loading={BtnLoading} onClick={nextFunction}>
            {nextBtnText || "Continue"}
          </ButtonPrimary>
        </div>
      </div>
    </div>
  );
};

export default CommonLayout;
