import { getStorage } from "firebase/storage";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const app = firebase.initializeApp({
    apiKey: "AIzaSyD1bPm1YvtQKwAyXNUxE--YbkbEwydCuCI",
    authDomain: "trademastertransaction-project.firebaseapp.com",
    projectId: "trademastertransaction-project",
    storageBucket: "trademastertransaction-project.appspot.com",
    messagingSenderId: "324510208107",
    appId: "1:324510208107:web:63af97fd7bea1f9e93212e"
});

const db = firebase.firestore();
const storage = getStorage(app);
const authentication = app.auth()

export { app, db, storage, authentication };
