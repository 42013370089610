import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectUser } from "redux/slice/userSlice";
import { CheckIcon } from "@heroicons/react/24/solid";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { SET_PRODUCT } from "redux/slice/productSlice";
import { db } from "utils/firebase";
import { selectIsPageIn } from "redux/slice/tokenSlice";
import { DecodeToken } from "utils/JwtConfig";

export interface PageSubcriptionProps {
  className?: string;
}

export interface PricingItem {
  isPopular: boolean;
  name: string;
  pricing: string;
  cost: number;
  desc: string;
  per: string;
  features: string[];
}

const pricings: PricingItem[] = [
  {
    isPopular: false,
    name: "Paquete Inicial",
    pricing: "$50",
    cost: 50,
    per: "",
    features: [
      "50 Puntos",
      "Soporte 24/7",
    ],
    desc: ` Descripcion y terminos y condiciones del plan`,
  },
  {
    isPopular: true,
    name: "Paquete Básico",
    pricing: "$100",
    cost: 100,
    per: "",
    features: [
      "100 Puntos",
      "10 Puntos de Regalo",
      "Todas las Publicaciones",
      "Soporte 24/7",
    ],
    desc: ` Descripcion y terminos y condiciones del plan`,
  },
  {
    isPopular: false,
    name: "Paquete Plus",
    pricing: "$300",
    cost: 300,
    per: "",
    features: [
      "300 Puntos",
      "30 Puntos de Regalo",
      "Todas las Publicaciones",
      "Soporte 24/7",
    ],
    desc: ` Descripcion y terminos y condiciones del plan`,
  },
];


const PageSubcription: FC<PageSubcriptionProps> = ({ className = "" }) => {
  const [typePost, setTypePost] = useState<any[]>([]);
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const token = useSelector(selectIsPageIn);
  const dispatch = useDispatch();

  const people = [
    { name: 'Jane Cooper', title: 'Regional Paradigm Technician' },
    { name: 'Cody Fisher', title: 'Product Directives Officer' },
  ]

  const goToCheckOut = async (pricing: PricingItem) => {
    if (user !== null) {
      dispatch(SET_PRODUCT({
        product: pricing
      }))
      navigate('/checkout');
    } else {
      navigate('/login');
    }
  };

  const renderPricingItem = (pricing: PricingItem, index: number) => {
    return (
      <div
        key={index}
        className={`h-full relative px-6 py-8 rounded-3xl border-2 flex flex-col overflow-hidden ${pricing.isPopular
          ? "border-primary-500"
          : "border-neutral-100 dark:border-neutral-700"
          }`}
      >
        {pricing.isPopular && (
          <span className="bg-primary-500 text-white px-3 py-1 tracking-widest text-xs absolute right-3 top-3 rounded-full z-10">
            POPULAR
          </span>
        )}
        <div className="mb-8">
          <h3 className="block text-sm uppercase tracking-widest text-neutral-6000 dark:text-neutral-300 mb-2 font-medium">
            {pricing.name}
          </h3>
          <h2 className="text-5xl leading-none flex items-center text-neutral-900 dark:text-neutral-300">
            <span>{pricing.pricing}</span>
            <span className="text-lg ml-1 font-normal text-neutral-500">
              {pricing.per}
            </span>
          </h2>
        </div>
        <nav className="space-y-4 mb-8">
          {pricing.features.map((item, index) => (
            <li className="flex items-center" key={index}>
              <span className="mr-4 inline-flex flex-shrink-0 text-primary-6000">
                <CheckIcon className="w-5 h-5" aria-hidden="true" />
              </span>
              <span className="text-neutral-700 dark:text-neutral-300">
                {item}
              </span>
            </li>
          ))}
        </nav>
        <div className="flex flex-col mt-auto">
          {pricing.isPopular ? (
            <ButtonPrimary onClick={() => { goToCheckOut(pricing) }}>Comprar</ButtonPrimary>
          ) : (
            <ButtonSecondary onClick={() => { goToCheckOut(pricing) }}>
              <span className="font-medium">Comprar</span>
            </ButtonSecondary>
          )}
          <p className="text-xs text-neutral-500 dark:text-neutral-400 mt-3">
            {pricing.desc}
          </p>
        </div>
      </div>
    );
  };

  const getPricePublications = async () =>{
    const PageData = await DecodeToken(token);
    db.collection(`portals/${PageData?.key}/data`).doc('post_type').get().then((Type_post)=>{
      setTypePost(Type_post.data()?.data);
    }).catch((error) => {
      console.log("🚀 ~ file: PageSubcription.tsx:152 ~ db.collection ~ error:", error)
    })
  };

  useEffect(()=>{
    getPricePublications()
  },[])

  return (
    <div
      className={`nc-PageSubcription container pb-24 lg:pb-32 ${className}`}
      data-nc-id="PageSubcription"
    >
      <header className="text-center max-w-2xl mx-auto my-20">
        <h2 className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          <span className="mr-4 text-3xl md:text-4xl leading-none"></span>
          Adquiere Puntos
        </h2>
        <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
          Precios que se adaptan a tus necesidades.
        </span>
      </header>
      <section className="text-neutral-600 text-sm md:text-base overflow-hidden">
        <div className="grid lg:grid-cols-3 gap-5 xl:gap-8">
          {pricings.map(renderPricingItem)}
        </div>
      </section>

      <section className="text-neutral-600 text-sm mt-16 md:text-base overflow-hidden">
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-primary-100">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
                      >
                        Tipo de publicación
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
                      >
                        Costo en puntos
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {typePost.map((type, personIdx) => (
                      <tr key={type.value} className={personIdx % 2 === 0 ? 'bg-white' : 'bg-primary-100'}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{type.name}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{type.cost}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PageSubcription;
