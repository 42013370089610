import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { toast } from 'react-toastify';
import googleSvg from "images/Google.svg";
import { CreateToken, DecodeToken } from "utils/JwtConfig";
import { SET_USER } from "redux/slice/userSlice";
import { db, authentication } from "utils/firebase";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { selectIsPageIn } from "redux/slice/tokenSlice";
export interface PageLoginProps {
  className?: string;
}

interface DataPage {
  name: any;
  subtitle: any;
}

const loginSocials = [
  // {
  //   name: "Continua con Facebook",
  //   href: "#",
  //   icon: facebookSvg,
  // },
  // {
  //   name: "Continua con Twitter",
  //   href: "#",
  //   icon: twitterSvg,
  // },
  {
    name: "Continua con Google",
    href: "#",
    icon: googleSvg,
  },
];

interface DataUser {
  address?: string,
  email?: string,
  user_id?: object,
  key: string,
  name?: string,
  phone: string,
  status: string,
  subscribed_clients: Array<any>,
  subscribed_portals: Array<any>,
  wallet: number;
}

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [dataPage, setDataPage] = useState<DataPage>();
  const [dataUSerExist, setDataUSerExist] = useState<DataUser | null>();
  const [userExist, setUserExist] = useState(false);
  const [user, setUser] = useState({
    user: "",
    pass: "",
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Token = useSelector(selectIsPageIn);

  const onSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    const DataToken = await DecodeToken(Token);

    authentication.signInWithEmailAndPassword(user.user, user.pass).then(res => {
      const uidUser = res.user?.uid;
      db.collection('users').doc(uidUser).get().then(async (doc) => {
        if (doc.exists) {
          const dataUser = doc.data() ?? {};
          dataUser.key = doc.id;
          const portalExist = dataUser.subscribed_portals.find((x: string) => x === DataToken?.key);
          const clientExist = dataUser?.subscribed_clients.find((x: string) => x === DataToken?.client_id);
          if (portalExist === undefined || clientExist === undefined) {
            const tempUser: DataUser = {
              address: dataUser.address,
              email: dataUser.email,
              key: dataUser.key,
              name: dataUser.name,
              phone: dataUser.phone,
              status: dataUser.status,
              subscribed_clients: dataUser.subscribed_clients,
              subscribed_portals: dataUser.subscribed_portals,
              wallet: dataUser.wallet,
              user_id: {
                number: dataUser.user_id.number,
                type: dataUser.user_id.type
              }
            };
            setDataUSerExist(tempUser);
            setLoading(false);
            setUserExist(true);
            toast.error('¡Usuario no registrado en este portal!', {
              position: "bottom-center",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            const Token = await CreateToken(dataUser);
            dispatch(SET_USER({
              user: Token
            }));

            toast.success('Éxito, Sesión iniciada', {
              position: "bottom-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setLoading(false);
            navigate('/');
          }
        }
      }).catch((error: any) => {
        setLoading(false);
        console.log("🚀 ~ file: Login.js:39 ~ authentication.signInWithEmailAndPassword ~ error:", error)
      })
    }).catch((error) => {
      console.log("🚀 ~ file: PageLogin.tsx:101 ~ authentication.signInWithEmailAndPassword ~ error:", error)
      if (error.code === 'auth/user-not-found') {
        setLoading(false);
        toast.error('Error, Usuario no valido', {
          position: "bottom-center",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else if (error.code === 'auth/wrong-password') {
        setLoading(false);
        toast.error('Error, Contraseña invalida', {
          position: "bottom-center",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else if (error.code === 'auth/invalid-login-credentials') {
        setLoading(false);
        toast.error('Error, usuario o contraseña invalida', {
          position: "bottom-center",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else if (error.code === 'auth/invalid-email') {
        setLoading(false);
        toast.error('La dirección de correo electrónico está mal formateada.', {
          position: "bottom-center",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    });
  };

  const updateUserRegister = async () => {
    setLoading2(true);
    const DataToken = await DecodeToken(Token);
    const portalExist = dataUSerExist?.subscribed_portals.find(x => x === DataToken?.key);
    const clientExist = dataUSerExist?.subscribed_clients.find(x => x === DataToken?.client_id);

    if (clientExist === undefined) {
      dataUSerExist?.subscribed_clients.push(DataToken?.client_id);
      dataUSerExist?.subscribed_portals.push(DataToken?.key);
      saveUpdate(dataUSerExist);
    } else if (clientExist !== undefined && portalExist === undefined) {
      dataUSerExist?.subscribed_portals.push(DataToken?.key);
      saveUpdate(dataUSerExist);
    }
  };

  const saveUpdate = async (Info: any) => {
    const DataToken = await DecodeToken(Token);

    db.collection('users').doc(Info.key).update({
      subscribed_clients: Info.subscribed_clients,
      subscribed_portals: Info.subscribed_portals
    }).then(() => {
      db.collection(`portals/${DataToken?.key}/data`).doc('users').get().then((UsersPortal) => {
        const tempUsersPortal = UsersPortal.data() ?? {};
        tempUsersPortal.users_quantity = tempUsersPortal.users_quantity + 1;
        db.collection(`portals/${DataToken?.key}/data`).doc('users').update({ users_quantity: tempUsersPortal.users_quantity }).then(async () => {
          const Token = await CreateToken(Info);
          dispatch(SET_USER({
            user: Token
          }));

          toast.success('Éxito, Sesión iniciada', {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setLoading2(false);
          navigate('/');
        }).catch((error) => {
          console.log("🚀 ~ file: PageSignUp.tsx:120 ~ db.collection ~ error:", error)
        })
      }).catch((error) => {
        console.log("🚀 ~ file: PageSignUp.tsx:116 ~ db.collection ~ error:", error)
      })
    }).catch((error) => {
      setLoading2(false);
      console.log("🚀 ~ file: PageSignUp.tsx:148 ~ db.collection ~ error:", error)
    })
  }

  const isFormValid = () => {
    return Object.values(user).every(value => value !== '');
  };

  const getPageData = async () => {
    const DataPage: any = await DecodeToken(Token);
    const TempPage: DataPage = {
      name: DataPage?.metadata.title,
      subtitle: DataPage?.metadata.subtitle
    }
    setDataPage(TempPage);
  };

  useEffect(() => {
    getPageData();
  }, [])

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>{`${dataPage?.name}`} || Iniciar Sesión</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Iniciar Sesión
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </a>
            ))}
          </div>
          {/* OR */}
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              O
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>
          {/* FORM */}
          {userExist === false &&
            <form className="grid grid-cols-1 gap-6" action="#" method="post">
              <label className="block">
                <span className="text-neutral-800 dark:text-neutral-200">
                  Dirección de correo electrónico
                </span>
                <Input
                  type="email"
                  placeholder=""
                  className="mt-1"
                  onChange={(e) => { setUser({ ...user, user: e.target.value }) }}
                />
              </label>
              <label className="block">
                <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                  Contraseña
                  <Link to="/forgot-pass" className="text-sm">
                    ¿Has olvidado tu contraseña?
                  </Link>
                </span>
                <Input
                  type="password"
                  className="mt-1"
                  onChange={(e) => { setUser({ ...user, pass: e.target.value }) }}
                />
              </label>
              <ButtonPrimary type="submit" disabled={!isFormValid()} loading={loading} onClick={($event) => { onSubmit($event) }}>Continuar</ButtonPrimary>
            </form>
          }

          {userExist === true &&
            <div className="grid grid-rows-2 gap-4">
              <h3 className="font-semibold text-2xl text-center mt-5"> Actualmente no te encuentras suscrito a este portal ¿deseas afiliarte? </h3>
              <ButtonPrimary className="!h-2/5" loading={loading2} onClick={updateUserRegister}>Afiliar</ButtonPrimary>
            </div>
          }

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            ¿Nuevo Usuario? {` `}
            <Link to="/signup">Crea una cuenta</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
