import * as jose from "jose";

export const CreateToken = async (Info: any) => {
  try {
    const secret = new TextEncoder().encode(
      "cc7e0d44fd473002f1c42167459001140ec6389b7353f8088f4d9a95f2f596f2"
    );

    const alg = "HS256";

    const jwt = await new jose.SignJWT(Info)
      .setProtectedHeader({ alg })
      .setIssuedAt()
      .setIssuer("urn:example:issuer")
      .setAudience("urn:example:audience")
      .setExpirationTime("3h")
      .sign(secret);

    return jwt;
  } catch (error) {
    console.log("🚀 ~ file: JwtConfig.ts:7 ~ CreateToken ~ error:", error);
  }
};

export const DecodeToken = async (Token: any) => {
  try {
    const decodedToken = jose.decodeJwt(Token);
    return decodedToken;
  } catch (error) {
    console.log("🚀 ~ file: JwtConfig.ts:29 ~ VerifyToken ~ error:", error);
  }
};

export const VerifyToken = async (Token: any) => {
  try {
    const decodedToken = jose.decodeJwt(Token);
    if (decodedToken && typeof decodedToken.exp === "number") {
      const expirationTime = decodedToken.exp;
      const currentTime = Math.floor(Date.now() / 1000); // Obtener la hora actual en segundos
      const Expire = expirationTime < currentTime;

      return Expire;
    }
  } catch (error) {
    console.log("🚀 ~ file: JwtConfig.ts:29 ~ VerifyToken ~ error:", error);
  }
};
