import React, { ReactNode, useEffect, useState } from "react";
import { imageGallery as listingStayImageGallery } from "./listing-stay-detail/constant";
import { imageGallery as listingCarImageGallery } from "./listing-car-detail/constant";
import { imageGallery as listingExperienceImageGallery } from "./listing-experiences-detail/constant";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ListingImageGallery from "components/ListingImageGallery/ListingImageGallery";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import MobileFooterSticky from "./(components)/MobileFooterSticky";
import { selectPost } from "redux/slice/postSlice";
import { useSelector } from "react-redux";
import { DecodeToken } from "utils/JwtConfig";
import { db } from "utils/firebase";
import { selectIsPageIn } from "redux/slice/tokenSlice";
import { selectUser } from "redux/slice/userSlice";
import { ListingGalleryImage } from "components/ListingImageGallery/utils/types";

interface PostData {
  images: any;
  price: any;
}

const DetailPagetLayout = ({ children }: { children: ReactNode }) => {
  const [postData, setPostData] = useState<PostData>();
  const navigate = useNavigate();
  const thisPathname = useLocation().pathname;
  const [searchParams] = useSearchParams();
  const modal = searchParams?.get("modal");
  const PostID = useSelector(selectPost);
  const Portal = useSelector(selectIsPageIn);
  const User = useSelector(selectUser);


  const handleCloseModalImageGallery = () => {
    let params = new URLSearchParams(document.location.search);
    params.delete("modal");
    navigate(`${thisPathname}/?${params.toString()}`);
  };

  const getImageGalleryListing = async () => {
    const PortalData = await DecodeToken(Portal)
    const currentUrl = window.location.href;
    const splitUrl = currentUrl.split('/');

    db.collection(`portals/${PortalData?.key}/posts`).doc(PostID === null ? splitUrl[4] : PostID).get().then(async (post) => {
      if (post.exists) {
        const imageGallery: ListingGalleryImage[] = [...post.data()?.images].map(
          (item, index): ListingGalleryImage => {
            return {
              id: index,
              url: item,
            };
          }
        );

        const TempPost: PostData = {
          images: imageGallery,
          price: post.data()?.price
        };

        setPostData(TempPost)
      } else {
        const DataUser = await DecodeToken(User);
        db.collection(`users/${DataUser?.key}/posts`).doc(PostID).get().then((post) => {
          const Firebaseimages = post.data()?.images === undefined ? [''] :post.data()?.images;
          const imageGallery: ListingGalleryImage[] = [...Firebaseimages].map(
            (item, index): ListingGalleryImage => {
              return {
                id: index,
                url: item,
              };
            }
          );
  
          const TempPost: PostData = {
            images: imageGallery,
            price: post.data()?.price
          };
  
          setPostData(TempPost)
        })
      }
    }).catch((error) => {
      console.log("🚀 ~ file: ListingStayDetailPage.tsx:565 ~ db.collection ~ error:", error)
    })


    // if (thisPathname?.includes("/listing-stay-detail")) {
    //   return listingStayImageGallery;
    // }
    // if (thisPathname?.includes("/listing-car-detail")) {
    //   return listingCarImageGallery;
    // }
    // if (thisPathname?.includes("/listing-experiences-detail")) {
    //   return listingExperienceImageGallery;
    // }

    // return [];
  };

  useEffect(() => {
    getImageGalleryListing();
  }, [])

  return (
    <div className="ListingDetailPage">
      <ListingImageGallery
        isShowModal={modal === "PHOTO_TOUR_SCROLLABLE"}
        onClose={handleCloseModalImageGallery}
        images={postData?.images}
      />

      <div className="container ListingDetailPage__content">{children}</div>

      {/* OTHER SECTION */}
      <div className="container py-12 lg:py-22">
        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionSliderNewCategories
            heading="Explore by types of stays"
            subHeading="Explore houses based on 10 types of stays"
            categoryCardType="card5"
            itemPerRow={5}
            sliderStyle="style2"
            uniqueClassName="ListingDetailPage"
          />
        </div>
        <SectionSubscribe2 className="pt-24 lg:pt-32" /> */}
      </div>

      {/* STICKY FOOTER MOBILE */}
      <MobileFooterSticky price={postData?.price}/>
    </div>
  );
};

export default DetailPagetLayout;
