import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    product: null,
}

const productSlice = createSlice({
    name: "product",
    initialState,
    reducers: {
        SET_PRODUCT: (state, action) => {
            state.product = action.payload.product;
        },
        REMOVE_PRODUCT: (state, action) => {
            state.product = action.payload.product;
        }
    }
});

export const { SET_PRODUCT, REMOVE_PRODUCT } = productSlice.actions;

export const selectProduct = (state: any) => state.product.product;

export default productSlice.reducer;