import React, { useEffect, useState } from "react";
import logoImg from "images/logo.png";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { DecodeToken } from "utils/JwtConfig";
import logoLightImg from "images/logo-light.png";
import { selectIsPageIn } from "redux/slice/tokenSlice";
// import LogoSvgLight from "./LogoSvgLight";
// import LogoSvg from "./LogoSvg";

export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
}


const Logo: React.FC<LogoProps> = ({
  img = logoImg,
  imgLight = logoLightImg,
  className = "w-24",
}) => {
  const [Img, setImg] = useState<string>('');

  const Token = useSelector(selectIsPageIn);

  const GetImgPage = async () => {
    const DataToken = await DecodeToken(Token);
    if(typeof DataToken?.media_logo === "string"){
      setImg(DataToken?.media_logo)
    }
  };

  useEffect(()=>{
    GetImgPage();
  },[])

  return (
    <Link
      to="/"
      className={`ttnc-logo inline-block text-primary-6000 focus:outline-none focus:ring-0 ${className}`}
    >
      {/* <LogoSvgLight />
      <LogoSvg /> */}

      {/* THIS USE FOR MY CLIENT */}
      {/* PLEASE UN COMMENT BELLOW CODE AND USE IT */}
      {img ? (
        <img
          className={`block max-h-12 ${imgLight ? "dark:hidden" : ""}`}
          src={Img}
          alt="Logo"
        />
      ) : (
        "Logo Here"
      )}
      {imgLight && (
        <img
          className="hidden max-h-12 dark:block"
          src={Img}
          alt="Logo-Light"
        />
      )}
    </Link>
  );
};

export default Logo;
