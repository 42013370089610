import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    postId: null,
}

const postSlice = createSlice({
    name: "post",
    initialState,
    reducers: {
        SET_POST: (state, action) => {
            state.postId = action.payload.post;
        },
        REMOVE_POST: (state, action) => {
            state.postId = action.payload.post;
        }
    }
});

export const { SET_POST, REMOVE_POST } = postSlice.actions;

export const selectPost = (state: any) => state.post.postId;

export default postSlice.reducer;