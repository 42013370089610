import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { authentication } from 'utils/firebase';
import { REMOVE_USER } from 'redux/slice/userSlice';
import { REMOVE_INVOICE } from 'redux/slice/invoiceSlice';
import { REMOVE_PRODUCT } from 'redux/slice/productSlice';

export interface PageSignOutProps {
    className?: string;
}

const PageSingOut: FC<PageSignOutProps> = ({ className = "" }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        authentication.signOut().then(() => {
            dispatch(REMOVE_USER({ user: null }));
            dispatch(REMOVE_INVOICE({ invoice: null }));
            dispatch(REMOVE_PRODUCT({ product: null }));
            navigate('/')
        }).catch((error) => {
            console.log("🚀 ~ file: SignOut.js:16 ~ authentication.signOut ~ error:", error)
        })
    }, [])

    return (
        <div className={`nc-PageSignOut  ${className}`} data-nc-id="PageSignOut">
            <div className="grid grid-rows-2 gap-4">
                <h3 className="font-semibold text-2xl text-center mt-5"> Sesión Cerrada Exitosamente </h3>
            </div>
        </div>
    )
}

export default PageSingOut