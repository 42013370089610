import React, { FC, Fragment, useEffect, useState } from "react";
import { Tab } from "@headlessui/react";
import { db } from "utils/firebase";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import Avatar from "shared/Avatar/Avatar";
import { DecodeToken } from "utils/JwtConfig";
import CarCard from "components/CarCard/CarCard";
import { selectUser } from "redux/slice/userSlice";
import StayCard from "components/StayCard/StayCard";
import SocialsList from "shared/SocialsList/SocialsList";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import StartRating from "components/StartRating/StartRating";
import CommentListing from "components/CommentListing/CommentListing";
import ExperiencesCard from "components/ExperiencesCard/ExperiencesCard";
import { selectIsPageIn } from "redux/slice/tokenSlice";

export interface AuthorPageProps {
  className?: string;
}

interface DataUser {
  address?: any,
  email?: any,
  id?: any,
  name?: any,
  phone?: any
  status?: any,
  subscribed_clients?: any
  subscribed_portals?: any
  wallet?: any
}

interface Post {
  title?: string;
  content?: string;
  key: string;
}

interface wallet {
  key: string;
}

interface DataPage {
  name: any;
  subtitle: any;
}

const AuthorPage: FC<AuthorPageProps> = ({ className = "" }) => {
  const [tabsPost, setTabsPost] = useState<any[]>([]);
  const [dataUser, setDataUser] = useState<DataUser>();
  const [dataPage, setDataPage] = useState<DataPage>();
  const [postUserData, setPostUserData] = useState<any[]>();
  const [dataWalletRecords, setDataWalletRecords] = useState<any[]>([]);
  const [dataPostsUserSales, setDataPostsUserSales] = useState<any[]>([]);
  const [dataPostsUserLegal, setDataPostsUserLegal] = useState<any[]>([]);
  const [dataPostsUserVehicles, setDataPostsUserVehicles] = useState<any[]>([]);
  const [dataPostsUserJobOffers, setDataPostsUserJobOffers] = useState<any[]>([]);
  const [dataPostsUserRealestate, setDataPostsUserRealestate] = useState<any[]>([]);
  let [categories] = useState(["Todas", "Aviso Legal", "Oferta Laboral", "Inmuebles", "Venta de Productos", "Publicación de Vehículo"]);

  const user = useSelector(selectUser);
  const Token = useSelector(selectIsPageIn);

  const getUserData = async () => {
    try {
      const DataPortal = await DecodeToken(Token);
      const DataUser = await DecodeToken(user);
      const postTabs = await db.collection(`portals/${DataPortal?.key}/data`).doc('post_type').get();
      const WalletDataRecords = await db.collection(`users/${DataUser?.key}/wallet`).orderBy('date_created', 'desc').get();
      const PostsData = await db.collection(`users/${DataUser?.key}/posts`).where('portal_id', '==', DataPortal?.key).where('status', '==', 'Completado').limit(8).get();
      const PostDataRealestate = await db.collection(`users/${DataUser?.key}/posts`).where('post_type', '==', 'Inmuebles').where('portal_id', '==', DataPortal?.key).where('status', '==', 'Completado').limit(8).get();
      const PostDataSales = await db.collection(`users/${DataUser?.key}/posts`).where('post_type', '==', 'Venta de Productos').where('portal_id', '==', DataPortal?.key).where('status', '==', 'Completado').limit(8).get();
      const PostDataJobOffers = await db.collection(`users/${DataUser?.key}/posts`).where('post_type', '==', 'Oferta Laboral').where('portal_id', '==', DataPortal?.key).where('status', '==', 'Completado').limit(8).get();
      const PostsDataVehicle = await db.collection(`users/${DataUser?.key}/posts`).where('post_type', '==', 'Publicacion de Vehiculo').where('portal_id', '==', DataPortal?.key).where('status', '==', 'Completado').limit(8).get();
      const PostDataLegal = await db.collection(`users/${DataUser?.key}/posts`).where('post_type', '==', 'Aviso Legal').where('portal_id', '==', DataPortal?.key).where('status', '==', 'Completado').limit(8).get();

      const TempUser: DataUser = {
        address: DataUser?.address,
        email: DataUser?.email,
        id: DataUser?.user_id,
        name: DataUser?.name,
        phone: DataUser?.phone,
        status: DataUser?.status,
        subscribed_clients: DataUser?.subscribed_clients,
        subscribed_portals: DataUser?.subscribed_portals,
        wallet: DataUser?.wallet
      }
      setDataUser(TempUser);

      const Post: Post[] = PostsData.docs.map((cadaPost) => {
        const TempPost = cadaPost.data();
        return { ...TempPost, key: cadaPost.id };
      });

      const PostVehicles: Post[] = PostsDataVehicle.docs.map((cadaPost) => {
        const TempPost = cadaPost.data();
        return { ...TempPost, key: cadaPost.id };
      });

      const PostJobOffer: Post[] = PostDataJobOffers.docs.map((cadaPost) => {
        const TempPost = cadaPost.data();
        return { ...TempPost, key: cadaPost.id };
      });

      const PostRealestate: Post[] = PostDataRealestate.docs.map((cadaPost) => {
        const TempPost = cadaPost.data();
        return { ...TempPost, key: cadaPost.id };
      });

      const PostSales: Post[] = PostDataSales.docs.map((cadaPost) => {
        const TempPost = cadaPost.data();
        return { ...TempPost, key: cadaPost.id };
      });

      const PostLegal: Post[] = PostDataLegal.docs.map((cadaPost) => {
        const TempPost = cadaPost.data();
        return { ...TempPost, key: cadaPost.id };
      });

      const WalletRecords: wallet[] = WalletDataRecords.docs.map((cadaRegistro) => {
        const TempWallet = cadaRegistro.data();
        return { ...TempWallet, key: cadaRegistro.id };
      });

      const Tabs: any[] = postTabs.data()?.data.map((cadatipoPost: any) => {
        return cadatipoPost.name;
      });

      const sortedTabs = ['Todas', ...Tabs].sort((a, b) => {
        const indexA = categories.indexOf(a);
        const indexB = categories.indexOf(b);
        return indexA - indexB;
      });

      setPostUserData(Post);
      setTabsPost(sortedTabs);
      setDataPostsUserLegal(PostLegal);
      setDataPostsUserSales(PostSales);
      setDataWalletRecords(WalletRecords);
      setDataPostsUserVehicles(PostVehicles);
      setDataPostsUserJobOffers(PostJobOffer);
      setDataPostsUserRealestate(PostRealestate);
    } catch (error) {
      console.log("🚀 ~ file: AuthorPage.tsx:90 ~ getUserData ~ error:", error)
    }
  };

  const renderSidebar = () => {
    return (
      <div className=" w-full flex flex-col items-center text-center sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-7 px-0 sm:p-6 xl:p-8">
        <Avatar
          hasChecked
          hasCheckedClass="w-6 h-6 -top-0.5 right-2"
          sizeClass="w-28 h-28"
        />

        {/* ---- */}
        <div className="space-y-3 text-center flex flex-col items-center">
          <h2 className="text-3xl font-semibold">{dataUser?.name}</h2>
          <StartRating className="!text-base" />
        </div>

        {/* ---- */}
        <p className="text-neutral-500 dark:text-neutral-400">
          Descripcion de prueba, este espacio es para poder mostrar la informacion.
        </p>

        {/* ---- */}
        <SocialsList
          className="!space-x-3"
          itemClass="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
        />

        {/* ---- */}
        <div className="border-b border-neutral-200 dark:border-neutral-700 w-14"></div>

        {/* ---- */}
        <div className="space-y-4">
          <div className="flex items-center space-x-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-neutral-400 mt-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="m19 2-8.4 7.05a1 1 0 0 1-1.2 0L1 2m18 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1m18 0v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2"
              />
            </svg>
            <span className="text-neutral-6000 dark:text-neutral-300">
              {dataUser?.email}
            </span>
          </div>
          <div className="flex items-center space-x-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-neutral-400 mt-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="m16.344 12.168-1.4-1.4a1.98 1.98 0 0 0-2.8 0l-.7.7a1.98 1.98 0 0 1-2.8 0l-2.1-2.1a1.98 1.98 0 0 1 0-2.8l.7-.7a1.981 1.981 0 0 0 0-2.8l-1.4-1.4a1.828 1.828 0 0 0-2.8 0C-.638 5.323 1.1 9.542 4.78 13.22c3.68 3.678 7.9 5.418 11.564 1.752a1.828 1.828 0 0 0 0-2.804Z"
              />
            </svg>
            <span className="text-neutral-6000 dark:text-neutral-300">
              +{dataUser?.phone}
            </span>
          </div>

          <div className="flex items-center space-x-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-neutral-400 mt-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M3.656 12.115a3 3 0 0 1 5.682-.015M13 5h3m-3 3h3m-3 3h3M2 1h16a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1Zm6.5 4.5a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"
              />
            </svg>
            <span className="text-neutral-6000 dark:text-neutral-300">
              {dataUser?.id.type}-{dataUser?.id.number}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Publicaciones de {dataUser?.name}</h2>
          {/* <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            NOMBRE USUARIO's listings is very rich, 5 star reviews help him to be
            more branded.
          </span> */}
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div>
          <Tab.Group>
            <Tab.List className="flex space-x-1 overflow-x-auto">
              {tabsPost.map((item) => (
                <Tab key={item} as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${selected
                        ? "bg-secondary-700 text-secondary-50 "
                        : "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                        } `}
                    >
                      {item}
                    </button>
                  )}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel className="">
                <div className="mt-8 grid grid-cols-3 gap-6 md:gap-7 sm:grid-cols-3">
                  {postUserData?.filter((_, i) => i < 6).map((stay) => (
                    <StayCard key={stay.key} data={stay} />
                  ))}
                </div>
                <div className="flex mt-11 justify-center items-center">
                  <ButtonSecondary loading={false} >Ver Todos</ButtonSecondary>
                </div>
              </Tab.Panel>

              {tabsPost.includes('Aviso Legal') &&
                <Tab.Panel className="">
                  <div className="mt-8 grid grid-cols-3 gap-6 md:gap-7 sm:grid-cols-3">
                    {dataPostsUserLegal?.filter((_, i) => i < 6).map((stay) => (
                      <StayCard key={stay.key} data={stay} />
                    ))}
                  </div>
                  <div className="flex mt-11 justify-center items-center">
                    <ButtonSecondary loading={false} >Ver Todos</ButtonSecondary>
                  </div>
                </Tab.Panel>
              }

              {tabsPost.includes('Oferta Laboral') &&
                <Tab.Panel className="">
                  <div className="mt-8 grid grid-cols-3 gap-6 md:gap-7 sm:grid-cols-3">
                    {dataPostsUserJobOffers?.filter((_, i) => i < 6).map((stay) => (
                      <StayCard key={stay.key} data={stay} />
                    ))}
                  </div>
                  <div className="flex mt-11 justify-center items-center">
                    <ButtonSecondary loading={false} >Ver Todos</ButtonSecondary>
                  </div>
                </Tab.Panel>
              }

              {tabsPost.includes('Inmuebles') &&
                <Tab.Panel className="">
                  <div className="mt-8 grid grid-cols-3 gap-6 md:gap-7 sm:grid-cols-3">
                    {dataPostsUserRealestate?.filter((_, i) => i < 6).map((stay) => (
                      <StayCard key={stay.key} data={stay} />
                    ))}
                  </div>
                  <div className="flex mt-11 justify-center items-center">
                    <ButtonSecondary loading={false} >Ver Todos</ButtonSecondary>
                  </div>
                </Tab.Panel>
              }

              {tabsPost.includes('Venta de Productos') &&
                <Tab.Panel className="">
                  <div className="mt-8 grid grid-cols-3 gap-6 md:gap-7 sm:grid-cols-3">
                    {dataPostsUserSales?.filter((_, i) => i < 6).map((stay) => (
                      <StayCard key={stay.key} data={stay} />
                    ))}
                  </div>
                  <div className="flex mt-11 justify-center items-center">
                    <ButtonSecondary loading={false} >Ver Todos</ButtonSecondary>
                  </div>
                </Tab.Panel>
              }

              {tabsPost.includes('Publicación de Vehículo') &&
                <Tab.Panel className="">
                  <div className="mt-8 grid grid-cols-3 gap-6 md:gap-7 sm:grid-cols-3">
                    {dataPostsUserVehicles?.filter((_, i) => i < 6).map((stay) => (
                      <StayCard key={stay.key} data={stay} />
                    ))}
                  </div>
                  <div className="flex mt-11 justify-center items-center">
                    <ButtonSecondary loading={false} >Ver Todos</ButtonSecondary>
                  </div>
                </Tab.Panel>
              }
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    );
  };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Transacciones</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* comment */}
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Puntos
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Tipo de Orden
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Estatus
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Fecha
                      </th>
                      <th scope="col" className="relative px-6 py-3">
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {dataWalletRecords.map((record) => (
                      <tr key={record.key}>
                        <td className="py-4 whitespace-nowrap">
                          <div className="ml-4">
                            <div className="text-sm text-center font-medium text-gray-900">{record.ammount}</div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-center text-gray-900">{record.order_type === 'add_points' ? 'Puntos añadidos' : 'Publicación de post'}</div>
                        </td>
                        <td className="px-6 py-4 text-center whitespace-nowrap">
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                            {record.status !== undefined ? record.status : 'completado'}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-center text-gray-500">{new Date(record.date_created.toDate()).toLocaleDateString("es-VE")}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getPageData = async () => {
    const DataPage: any = await DecodeToken(Token);
    const TempPage: DataPage = {
      name: DataPage?.metadata.title,
      subtitle: DataPage?.metadata.subtitle
    }
    setDataPage(TempPage);
  };

  useEffect(() => {
    getUserData();
    getPageData();
  }, [])

  return (
    <div className={`nc-AuthorPage ${className}`} data-nc-id="AuthorPage">
      <Helmet>
        <title>{`${dataPage?.name}`} || {`${dataPage?.subtitle}`}</title>
      </Helmet>
      <main className="container mt-12 mb-24 lg:mb-32 flex flex-col lg:flex-row">
        <div className="block flex-grow mb-24 lg:mb-0">
          <div className="lg:sticky lg:top-24">{renderSidebar()}</div>
        </div>
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pl-10 flex-shrink-0">
          {renderSection1()}
          {renderSection2()}
        </div>
      </main>
    </div>
  );
};

export default AuthorPage;
