import { SocialType } from "shared/SocialsShare/SocialsShare";
import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectIsPageIn } from "redux/slice/tokenSlice";
import { DecodeToken } from "utils/JwtConfig";

export interface SocialsList1Props {
  className?: string;
}

const SocialsList1: FC<SocialsList1Props> = ({ className = "space-y-2.5" }) => {
  const [socials, setSocials] = useState<SocialType[]>([
    { name: "Facebook", icon: "lab la-facebook-square", href: "#" },
    { name: "Twitter", icon: "lab la-twitter", href: "#" },
    { name: "Youtube", icon: "lab la-youtube", href: "#" },
    { name: "Instagram", icon: "lab la-instagram", href: "#" },
  ])

  const Token = useSelector(selectIsPageIn);

  const getSocialLinks = async () => {
    const DataPage: any = await DecodeToken(Token)
    const TempSocials: SocialType[] = [
      { name: "Facebook", icon: "lab la-facebook-square", href: addProtocolToURL(DataPage?.metadata.social_facebook) },
      { name: "Twitter", icon: "lab la-twitter", href: addProtocolToURL(DataPage?.metadata.social_x) },
      { name: "Youtube", icon: "lab la-youtube", href: addProtocolToURL(DataPage?.metadata.social_youtube) },
      { name: "Instagram", icon: "lab la-instagram", href: addProtocolToURL(DataPage?.metadata.social_instagram) },
    ]
    setSocials(TempSocials);
  };

  const addProtocolToURL = (url: any) => {
    if (typeof url === 'string' && (url.startsWith("http://") || url.startsWith("https://"))) {
      return url;
    } else if (typeof url === 'string') {
      return `https://${url}`;
    } else {
      return ''; 
    }
  };


  const renderItem = (item: SocialType, index: number) => {
    return (
      <a
        href={item.href}
        className="flex items-center text-2xl text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white leading-none space-x-2 group"
        key={index}
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className={item.icon}></i>
        <span className="hidden lg:block text-sm">{item.name}</span>
      </a>
    );
  };

  useEffect(() => {
    getSocialLinks();
  }, [])
  return (
    <div className={`nc-SocialsList1 ${className}`} data-nc-id="SocialsList1">
      {socials.map(renderItem)}
    </div>
  );
};

export default SocialsList1;
