import React, { FC, Fragment, useState } from "react";
import { db } from "utils/firebase";
import visaPng from "images/vis.png";
import { toast } from 'react-toastify';
import Input from "shared/Input/Input";
import { Tab } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import Label from "components/Label/Label";
import { CreateToken, DecodeToken } from "utils/JwtConfig";
import { loadStripe } from '@stripe/stripe-js';
import { useNavigate } from "react-router-dom";
import Textarea from "shared/Textarea/Textarea";
import mastercardPng from "images/mastercard.svg";
import { SET_USER, selectUser } from "redux/slice/userSlice";
import { Elements } from '@stripe/react-stripe-js';
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { selectProduct } from "redux/slice/productSlice";
import StartRating from "components/StartRating/StartRating";
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { SET_INVOICE } from "redux/slice/invoiceSlice";
import Checkout from 'images/Checkout.png';
import { selectIsPageIn } from "redux/slice/tokenSlice";


// Crea una instancia de Stripe utilizando tu clave pública
const stripePromise = loadStripe(
  'pk_test_51O8OhSEg11OqJ0gjVGVThVkrInkkd8OhsxohgyqSZL8UQRPELoQaK02edu8zah0QUgVNcpybytwSCKp2h3Uu21N600Q9joYGZA'
)

export interface CheckOutPagePageMainProps {
  className?: string;
}

const App = (amount: any) => {
  const [paymentError, setPaymentError] = useState('');
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [clientSecret, setClientSecret] = useState('');
  const [loading, setLoading] = useState(false);

  const stripe = useStripe();
  const elements = useElements();
  const user = useSelector(selectUser);
  const token = useSelector(selectIsPageIn);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const updatedUserData = (id: any) => {
    db.collection('users').doc(id).get().then(async (doc) => {
      const dataUser = doc.data() ?? {};
      dataUser.key = doc.id;
      const Token = await CreateToken(dataUser);
      dispatch(SET_USER({
        user: Token
      }));
      toast.success('Éxito, Pago realizado exitosamente.', {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      navigate('/pay-done')
    }).catch((error) => {
      setLoading(false);
      console.log("🚀 ~ file: CheckOutPage.tsx:45 ~ db.collection ~ error:", error)
    })
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault()

    const userData = await DecodeToken(user);
    const PageData = await DecodeToken(token);
    setLoading(true);

    if (!stripe || !elements) {
      // Stripe no se ha cargado aún, no hacer nada
      return
    }

    try {
      const response = await fetch(
        'https://api.stripe.com/v1/payment_intents',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization:
              'Bearer sk_test_51O8OhSEg11OqJ0gjes3lSmeThPFExlI1yv3MjdB8J3a0vIH4JSipCigPd3EXHVGAjlW9xWeorfH0t31wFAEMup9D00Fr12YWV3',
          },
          body: new URLSearchParams({
            amount: amount.amount,
            currency: 'usd',
            'automatic_payment_methods[enabled]': 'true',
          }).toString(),
        }
      )

      const data = await response.json()
      setClientSecret(data.client_secret)
      // Crea un objeto de pago con Stripe

      const cardElement = elements.getElement(CardElement)
      if (!cardElement) {
        // Manejar el caso cuando el elemento de tarjeta no está disponible
        return
      }

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      })

      if (error) {
        console.log(error)
        setLoading(false);
        // setPaymentError("Error en el pago");
        // setPaymentSuccess(false);
      } else {
        const { error: confirmError, paymentIntent } =
          await stripe.confirmCardPayment(data.client_secret, {
            payment_method: paymentMethod?.id,
          })
        if (confirmError) {
          setLoading(false);
          toast.error('Error, pago no realizado', {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else if (paymentIntent?.status === 'succeeded') {

          const data = {
            product_reference: 'Plan Basico',
            payment_currency: 'dollar',
            status: 'Pagado',
            client_name: PageData?.client,
            date_created: new Date(),
            date_updated: new Date(),
            client_id: PageData?.client_id,
            payment_reference:
              paymentIntent?.receipt_email == undefined
                ? ''
                : paymentIntent?.receipt_email,
            payment_subtotal: paymentIntent?.amount,
            payment_status: 'Completado',
            payment_method: 'Stripe',
            order_id: paymentIntent?.id,
            payment_total: paymentIntent?.amount,
            order_owner: 'user',
            order_type: 'add_points'
          }

          const walletData = {
            ammount: paymentIntent?.amount,
            data: {
              payment_type: 'Stripe',
            },
            date_created: new Date(),
            date_updated: new Date(),
            order_type: 'add_points',
            user_name: userData?.name,
            user_id: userData?.key,
            client_name: PageData?.client,
            client_id: PageData?.client_id,
            status: 'Completado',
            product_reference: ''
          }

          // Crea una instancia de batch
          const batch = db.batch();

          // Crea una referencia a la colección "orders"
          const ordersRef = db.collection('orders');

          // Crea una referencia a la colección "users"
          const usersRef = db.collection('users');

          // Genera un nuevo ID para el documento en "orders"
          const newOrderRef = ordersRef.doc();
          const newOrderId = newOrderRef.id;

          // Crea los datos que deseas agregar en "orders"
          const orderData = { ...data };

          // Agrega la escritura en "orders" al lote
          batch.set(newOrderRef, orderData);

          // Crea una referencia a la colección "users/{userId}/orders"
          const userOrdersRef = usersRef.doc(`${userData?.key}`).collection('orders');

          // Genera un nuevo ID para el documento en "users/{userId}/orders"
          const newUserOrderRef = userOrdersRef.doc(newOrderId);

          // Agrega la escritura en "users/{userId}/orders" al lote
          batch.set(newUserOrderRef, orderData);

          //actualiza el valor de product_reference con el id de la orden
          walletData.product_reference = newOrderId;

          // Crea una referencia a la colección "users/{userId}/wallet"
          const userWalletRef = usersRef.doc(`${userData?.key}`).collection('wallet');

          // Genera un nuevo ID para el documento en "users/{userId}/wallet"
          const newUserWalletRef = userWalletRef.doc(newOrderId);

          // Agrega la escritura en "users/{userId}/orders" al lote
          batch.set(newUserWalletRef, walletData);

          // Calcula el nuevo valor de "wallet"
          const totalWallet = userData?.wallet + amount.amount;

          // Crea una referencia al documento del usuario en "users"
          const userRef = usersRef.doc(`${userData?.key}`);

          // Agrega la escritura para actualizar "wallet" al lote
          batch.update(userRef, { wallet: totalWallet });

          // Ejecuta el lote de escrituras
          batch.commit().then(function () {
            updatedUserData(userData?.key);
            dispatch(SET_INVOICE({ invoice: newOrderId }));
          }).catch(function (error) {
            console.error('Error al realizar las escrituras por lote:', error);
          });
        } else {
          setLoading(false);
          toast.error('Error al procesar el pago.', {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      }
    } catch (error) {
      setLoading(false);
      console.log("🚀 ~ file: CheckOutPage.tsx:185 ~ handleSubmit ~ error:", error)
    }
  };

  return (
    <div>
      <div className="p-3">
        <label>
          Tarjeta de crédito
        </label>
        <CardElement className="mt-5" />
      </div>
      <ButtonPrimary onClick={handleSubmit} className="mt-8" loading={loading}>Pagar</ButtonPrimary>
      {paymentError && <p>Error: {paymentError}</p>}
      {paymentSuccess && <p>Pago realizado con éxito</p>}
    </div>
  )
}

const CheckOutPagePageMain: FC<CheckOutPagePageMainProps> = ({
  className = "",
}) => {

  const product = useSelector(selectProduct);

  const renderSidebar = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
        <div className="flex flex-col sm:flex-row sm:items-center">
          <div className="flex-shrink-0 w-full sm:w-40">
            <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
              <img
                alt=""
                className="absolute inset-0 object-cover"
                sizes="200px"
                src={Checkout}
              />
            </div>
          </div>
          <div className="py-5 sm:px-5 space-y-3">
            <div>
              <span className="text-base font-medium mt-1 block">
                {product.name}
              </span>
            </div>
            <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
              {product.features[0]}
            </span>
            <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
            <StartRating />
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <h3 className="text-2xl font-semibold">Detalles del producto</h3>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>{product.features[0]}</span>
            <span>{product.pricing}</span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Cargo por Plataforma:</span>
            <span>$0</span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Impuestos:</span>
            <span>$0</span>
          </div>

          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Total a Pagar:</span>
            <span>{product.pricing} / {Number(product.cost) * 35.39} Bs</span>
          </div>
        </div>
      </div>
    );
  };

  const renderMain = () => {
    const PriceProduct = product.pricing.split('$')

    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          Confirmar y Pagar
        </h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <h3 className="text-2xl font-semibold">Selecciona el método de pago:</h3>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div>

          <div className="mt-6">
            <Tab.Group>
              <Tab.List className="flex my-5 gap-1">
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`px-4 py-1.5 sm:px-6 sm:py-2.5 rounded-full focus:outline-none ${selected
                        ? "bg-neutral-800 dark:bg-neutral-200 text-white dark:text-neutral-900"
                        : "text-neutral-6000 dark:text-neutral-400 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                        }`}
                    >
                      Tarjeta de crédito
                    </button>

                  )}
                </Tab>
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`px-4 py-1.5 sm:px-6 sm:py-2.5 rounded-full focus:outline-none ${selected
                        ? "bg-neutral-800 dark:bg-neutral-200 text-white dark:text-neutral-900"
                        : "text-neutral-6000 dark:text-neutral-400 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                        }`}
                    >
                      Pago móvil
                    </button>

                  )}
                </Tab>
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`px-4 py-1.5 sm:px-6 sm:py-2.5 rounded-full focus:outline-none ${selected
                        ? "bg-neutral-800 dark:bg-neutral-200 text-white dark:text-neutral-900"
                        : "text-neutral-6000 dark:text-neutral-400 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                        }`}
                    >
                      Efectivo
                    </button>

                  )}
                </Tab>
              </Tab.List>

              <Tab.Panels>
                <Tab.Panel className="space-y-10">
                  <div className="space-y-3 mt-10 px-2">
                    <Elements stripe={stripePromise}>
                      <App amount={Number(PriceProduct[1])} />
                    </Elements>
                  </div>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-CheckOutPagePageMain ${className}`}>
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">{renderMain()}</div>
        <div className="hidden lg:block flex-grow">{renderSidebar()}</div>
      </main>
    </div>
  );
};

export default CheckOutPagePageMain;
